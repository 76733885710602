
/*******************************
            Container
*******************************/

body{
  background:$color-lightgray;
  &.mobile-active{
    overflow-y:hidden;
  }
}

/* All Sizes */
.container {
  display: block;
  max-width: 1280px !important;
  margin:0 auto;

  /* Large Monitor */
  @include viewport(desktop) {
      max-width:1280px;
      margin-left: auto !important;
      margin-right: auto !important;
  }

  /* Small Monitor */
  @include viewport(laptop){
      max-width:1280px;
      margin-left: auto !important;
      margin-right: auto !important;
      padding-left:em(40px);
      padding-right:em(40px);

  }

  /* Tablet */
  @include viewport(tablet){
    max-width:100%;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left:em(40px);
    padding-right:em(40px);
  }

  /* Mobile */
  @include viewport(mobile){

      width: auto !important;
      margin-left: auto !important;
      margin-right: auto !important;
      padding-left:em(40px);
      padding-right:em(40px);
  }


}



/*******************************
    Site Header & Footer
*******************************/
.site-header{
  background:$color-black;
  position:relative;
  .container{
    @include flexbox();
    justify-content: space-between;
    flex-wrap: wrap;
    flex-flow:row wrap;
    align-items:center;
    padding:em(40px) 0 !important;
    @include viewport(tablet){
      padding:em(40px) !important;
    }
    .logo{
      @include viewport(mobile){
        max-width:50%;
      }
    }
  }
}

#inside-hero{
  position:absolute;
  top:0;
  z-index:9;
  width:100%;
  background:none;
  .container{
    @include flexbox();
    justify-content: space-between;
    flex-wrap: wrap;
    flex-flow:row wrap;
  }
}

.site-footer{
  background:$color-darkblue;
  min-height:em(200px);
  .container{
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-flow:row wrap;
    color:$color-white;
    padding:em(40px) 0;
    @include viewport(tablet){
      padding:em(40px);
    }
    h4{
      color:$color-white;
      font-family:$font__highlight;
      margin-bottom:0px;
    }
    p{
      @include font-size(0.75);
    }
    ul{
      list-style:none;
      margin:0 0 em(50px) 0;
    }
    a{
      color:$color-white;
    }
    .column-left{
      width:50%;
      @include viewport(mobile){
        width:100%;
      }
    }
    .column-right{
      width:50%;
      @include viewport(mobile){
        width:100%;
      }
    }
  }
}


.content{
  padding:em(40px) 0;
/*  min-height:1000px; */
}


.row {
  @include flexbox();
  justify-content: space-between;
  flex-wrap: wrap;
  flex-flow:row wrap;
  width:100%;
  &.four-columns{
    .column{
      width:23%;
      min-height:100px;
      position:relative;
      @include viewport(tablet){
        width:100%;
      }
      .item{
        position:relative;
        .image{
          -webkit-filter: grayscale(1);
          -webkit-filter: grayscale(90%);
          filter: gray;
          filter: grayscale(90%);
        }

        .title{
          @include font-size(1);
          margin-top:20px;
        }
      }
    }
  }
  &.three-columns{
    .column{
      width:30%;
      min-height:100px;
      position:relative;
      @include viewport(tablet){
        width:100%;
      }
      .item{
        position:relative;
        .image{
          -webkit-filter: grayscale(1);
          -webkit-filter: grayscale(90%);
          filter: gray;
          filter: grayscale(90%);
        }

        .title{
          @include font-size(1);
          margin-top:20px;
        }
      }
    }
  }
  &.two-columns{
    .column{
      width:48%;
      min-height:100px;
      @include viewport(tablet){
        width:100%;
      }
      .item{
        .title{
          @include font-size(1);
          margin-top:20px;
        }
      }
    }
  }
}
