.alignleft {
	display: inline;
	float: left;
	text-align:left;
	margin-right: 1.5em;
}

.alignright {
	display: inline;
	float: right;
	text-align:right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	text-align:center;
	@include center-block;
}




#page .alignleft {
	display: inline;
	float: left;
	margin: .5em 2em .5em 0;

	@include viewport(mobile) {
		max-width: 150px;
		margin-right: 1em;
	}
}

#page .alignright {
	display: inline;
	float: right;
	margin: .5em 0 1.5em 2em;

	@include viewport(mobile) {
		max-width: 150px;
		margin-left: 1em;
	}
}

#page .aligncenter {
	clear: both;
	display: block;
	margin: 0 auto 1.5em auto;
}

.wp-caption {
    margin: .5em 0 1.5em 0;
	max-width: 100%;
	text-align: left;
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin: 0;
	max-width: 100%;
}

.wp-caption-text, .gallery-caption {
	padding-top: 10px;
	margin-top: 0;
	width: 100%;
	text-transform: none;
	font-size: 15px;
	font-weight: 400;
	letter-spacing: 0;
	margin-bottom: 0;
	color: $color-gray;
}

.site-footer .wp-caption-text,
.site-footer .gallery-caption {
	color: #a6b5c1;
	border-left-color: #a6b5c1;
}

#main .tiled-gallery {
	margin-bottom: 1.4em;
}

.pull-right,
.pull-excerpt {
	float: right;
	margin: 1.2% 0 2% 5%;
	text-align: right;
}

.pull-left {
	float: left;
	margin: 1.5% 5% 2% 0;
}

.pull-right,
.pull-left,
.pull-excerpt {
	color: $color-black;
	font-family: $font__main;
	font-size: 20px;
	line-height: 1.4;
	width: 32%;
	padding: 20px 0 0 0;
	border-top: solid 4px $color-black;

	@include viewport(tablet) {
		font-size: 18px;
		width: 100%;
		margin: 0 0 5% 0;
	}
}

.pull-excerpt {
	font-style: normal;
	text-align: left;
	padding-top: 18px;
	line-height: 1.6;
	margin-top: 9px;

	@include viewport(tablet) {
		font-size: 20px;
		margin-top: 0;
	}
}

.sticky {
	width: auto;
}