
.site-header {
  background: #131313;
  position: relative;
  z-index: 99;
  clear: both;
  height: 116px;
}

.slick-slider.homepage-slider {
  position: relative;
  clear: both;
  float: left;
}

.site-header .container, .site-footer {
  padding-left: em(40px);
  padding-right: em(40px);
}

.home-product-types {
  .image {
    background-size: cover;
    height: 300px;
    background-position: center center;
    position: relative;

    .caption {
      width: 100%;
      position: absolute;
      bottom: 0px;
      padding: 20px;
      color: #fff;


      p {
        margin-bottom: 0;
      }

      h5 {
        color: #fff;
      }
    }
  }

  .column {
    &:first-child {
      .caption {
        background: $color__primary;

        h5 {
          color: $color-black;
        }

        p {
          color: $color-black;
        }
      }
    }

    &:last-child {
      .caption {
        background: $color__secondary;
      }

      h5 {
        color: $color-white;
      }

      p {
        color: $color-white;
      }
    }
  }

}

.offers {
  .image {
    background-size: cover;
    height: 300px;
    background-position: center center;
    position: relative;

    .caption {
      width: 100%;
      position: absolute;
      bottom: 0px;
      padding: 20px;
      color: #fff;


      p {
        margin-bottom: 0;
      }

      h5 {
        color: #fff;
      }
    }
  }

  .column {
    .caption {
      background: $color__primary;

      h5 {
        color: $color-black;
      }

      p {
        color: $color-black;
      }
    }

  }

}

.menu-main .item {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #FFFFFF;
  text-transform: uppercase;
}

.top-nav {
  text-align: right;
}

.slick-dots {
  bottom: 15px;

  li button {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    background-color: #ccc;
  }

  li.slick-active button {
    background-color: #FFF200;
  }
}

h2.page-heading {
  display: inline-block;
  padding-bottom: 5px;
  position: relative;
}

h2.page-heading:before {
  content: "";
  position: absolute;
  width: 100px;
  height: 7px;
  bottom: 0;
  border-bottom: 7px solid #FFF200;
}

.site-footer {
  background-color: #131313;

  h2 {
    color: #fff;
  }
}


/*************************************************/
#inside-hero .container {
  display: -moz-box;
  display: -webkit-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-flow: row wrap;
}

@media (max-width: 1024px) {
  .site-footer .container p {
    font-size: 0.63rem;
  }
  .site-footer .container ul {
    margin: 0 0 1.5em 0;
  }
}

.row {
  display: -moz-box;
  display: -webkit-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-flow: row wrap;
}

body,
button,
input,
select,
textarea {
  font-family: $font__heading;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font__heading;
}

h2 {
  font-size: 2.25rem;
}

p {
  font-family: $font__heading;
  font-size: 1.25em;
  line-height: 1.7;
  margin-top: 0;
  font-weight: 300;
}

body {
  background: #fff;
}

.feed-column {
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
}

.feed-column .item .entry-image {
  -moz-transition: all .2s ease-out;
  -ms-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
}

#nav-icon.open span:nth-child(2) {
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}

#nav-icon {
  top: 2.5em;
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
}

#nav-icon span {
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
}

.menu-main {
  display: -moz-box;
  display: -webkit-flex;
  align-self: center;
  text-transform: uppercase;
}

.menu-mobile.active {
  display: -moz-box;
  display: -webkit-flex;
  align-items: center;
}

.menu-mobile ul li a {
  color: #fbfbfb;
  line-height: 2.2em;
  font-family: $font__heading;
}

.form .field {
  margin: 0em 0em 2.0em;
}

.form input:not([type]), .form input[type="text"] {
  padding: 0.77861429em 1em;
  background: #e8e8e8;
  border: 1px solid rgba(232, 232, 232, 1.0);
  border-radius: 0;
}

.form textarea {
  background: #e8e8e8;
  border: 1px solid rgba(232, 232, 232, 1.0);
  border-radius: 0;
}

.form .ten.fields .input input, .form .nine.fields .input input, .form .eight.fields .input input, .form .seven.fields .input input, .form .six.fields .input input, .form .five.fields .input input, .form .four.fields .input input, .form .three.fields .input input, .form .two.fields .input input, .form .wide.field .input input {
  -webkit-flex: 1 0 auto;
}

.form input:not([type]):focus, .form input[type="text"]:focus {
  border-radius: 0;
}

.form textarea:focus {
  border-radius: 0;
}

.form.warning .icon.warning.message:not(:empty) {
  display: -webkit-flex;
}

.form.error .icon.error.message:not(:empty) {
  display: -webkit-flex;
}

.form .fields {
  display: -webkit-flex;
  -webkit-flex-direction: row;
}

.form .fields > .field {
  -webkit-flex: 0 1 auto;
}

@media only screen and (max-width: 767px) {
  .form .fields {
    -ms-flex-wrap: wrap;
  }

  [class*="equal width"].form .fields > .field,
  .form [class*="equal width"].fields > .field {
    -webkit-flex: 1 1 auto;
  }
}

.form .inline.fields .wide.field {
  display: -webkit-flex;
  -webkit-align-items: center;
}

.slick-slider .slick-list {
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
}

.slick-prev, .slick-next {
  -ms-transform: translate(0, -50%);
}

.slick-dotted.slick-slider {
  margin-bottom: 0px;
}

.accordion .title:not {
  padding: 0.5em 0em;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.87);
}

.accordion:not(.styled) .title ~ .content:not,
.accordion:not(.styled) .accordion .title ~ .content:not {
  margin: '';
  padding: 0.5em 0em 1em;
}

.accordion:not(.styled) .title ~ .content:not:last-child {
  padding-bottom: 0em;
}

.inverted.accordion .title:not {
  color: rgba(255, 255, 255, 0.9);
}

.radio.checkbox .box:after, .radio.checkbox label:after {
  -webkit-transform: scale(0.46667);
  transform: scale(0.46667);
}

.modal > .header:not {
  font-size: 1.42857143rem;
  line-height: 1.2857em;
  font-weight: bold;
}

.small.modal > .header:not {
  font-size: 1.3em;
}

.dropdown .menu > .message:not {
  color: rgba(0, 0, 0, 0.4);
}

.selection.dropdown .menu {
  min-width: calc(100% + 2px);
  width: calc(100% + 2px);
}

.scrolling.dropdown .menu .item.item.item, .dropdown .scrolling.menu > .item.item.item {
  padding-right: calc(1.14285714rem + 17px) !important;
}

@media all and (-ms-high-contrast: none) {
  .scrolling.dropdown .menu,
  .dropdown .scrolling.menu {
    min-width: calc(100% - 17px);
  }
}

.message .header:not {
  font-size: 1.14285714em;
}

.message .list:not {
  text-align: left;
  padding: 0em;
  opacity: 0.85;
  list-style-position: inside;
  margin: 0.5em 0em 0em;
}

icon.visible.visible.visible.visible.message {
  display: -webkit-flex;
}

.icon.message {
  display: -webkit-flex;
  -webkit-align-items: center;
}

.icon.message > .icon:not(.close) {
  -webkit-flex: 0 0 auto;
}

.icon.message > .content {
  -webkit-flex: 1 1 auto;
}

.colors {
  display: -moz-box;
  display: -webkit-flex;
  justify-content: space-around;
  flex-flow: row wrap;
}

.site-header {
  background-color: #231D1F;
  position: relative;
  z-index: 99;
  clear: both;
  border-bottom: 5px solid #535353;
}

.slick-slider.homepage-slider {
  position: relative;
  clear: both;
  float: left;
}

.site-header .container {
  padding-top: 40px;
  padding-bottom: 30px;
}

.site-header .container, .site-footer {
  padding-left: 2.5em;
  padding-right: 2.5em;
}

.home-product-types .image {
  background-size: cover;
  height: 300px;
  background-position: center center;
  position: relative;

  .caption {
    width: 100%;
    position: absolute;
    bottom: 0px;
    padding: 20px;
    color: #fff;
    z-index: 2;

    &:first-child {
      background: $color__primary !important;
    }
  }

  .caption p {
    margin-bottom: 0;
  }

  .caption h5 {
    color: #fff;
  }
}

.home-product-types div.imageOverlay {
  background-size: cover;
  height: 300px;
  background-position: center center;
  background: rgba(148, 147, 146, 1);
  background: -moz-linear-gradient(top, rgba(148, 147, 146, 0) 0%, rgba(0, 0, 0, 0.68) 80%, rgba(0, 0, 0, 0.6) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(148, 147, 146, 0)), color-stop(80%, rgba(0, 0, 0, 0.68)), color-stop(100%, rgba(0, 0, 0, 0.6)));
  background: -webkit-linear-gradient(top, rgba(148, 147, 146, 0) 0%, rgba(0, 0, 0, 0.68) 80%, rgba(0, 0, 0, 0.6) 100%);
  background: -o-linear-gradient(top, rgba(148, 147, 146, 0) 0%, rgba(0, 0, 0, 0.68) 80%, rgba(0, 0, 0, 0.6) 100%);
  background: -ms-linear-gradient(top, rgba(148, 147, 146, 0) 0%, rgba(0, 0, 0, 0.68) 80%, rgba(0, 0, 0, 0.6) 100%);
  background: linear-gradient(to bottom, rgba(148, 147, 146, 0) 0%, rgba(0, 0, 0, 0.68) 80%, rgba(0, 0, 0, 0.6) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#949392', endColorstr='#000000', GradientType=0);
  width: 100%;
  position: absolute;
  z-index: 1;
}

.top-nav {
  text-align: right;
}

.slick-dots {
  bottom: 15px;
}

.slick-dots {
  li {
    height: 13px;
    width: 13px;
    margin-left: 8px;
  }

  li button:before {
    content: "";
    width: 13px;
    height: 13px;
  }

  li button {
    height: 13px;
    width: 13px;
    border-radius: 10px;
    border: 0px solid #ccc;
    background-color: #ccc;
  }

  li.slick-active button {
    background-color: yellow;
  }
}

h2.page-heading {
  display: inline-block;
  padding-bottom: 25px;
  position: relative;
  font-family: $font__heading;
  font-weight: 500;
  margin-bottom: 20px;
}

h2.page-heading:before {
  content: "";
  position: absolute;
  width: 100px;
  height: 7px;
  bottom: 0;
  border-bottom: 7px solid yellow;
}

ul, ol {
  margin: 0;
}

.menu-main .item:hover {
  color: #FFF200;
}

.hero {
  height: 802px;
}

/*************************************************/


.stripLine {
  background: #221d20;
  background: -moz-linear-gradient(-45deg, #221d20 0%, #221d20 31%, #fff200 31%, #fff200 100%);
  background: -webkit-linear-gradient(-45deg, #221d20 0%, #221d20 31%, #fff200 31%, #fff200 100%);
  background: linear-gradient(120deg, #221d20 0%, #221d20 31%, #fff200 31%, #fff200 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#221d20', endColorstr='#fff200', GradientType=1);
  margin-bottom: 30px;
}

.clear-fix {
  clear: both;
}

.StrLogo {
  float: left;
  width: 30%;
  text-align: left;

  img {
    padding: 28px 0px;
  }
}

.StrMid {
  float: left;
  width: 50%;
  text-align: center;
  padding: 17px 0px 10px;
  font-family: $font__heading;
  color: #1b1b1b;
}

.StrBtnWrap {
  float: left;
  width: 20%;
  text-align: right;

  img {
    padding: 20px 0px;
  }
}

.btnStl {
  height: 58px;
  width: 166px;
  background-color: #231D1F;
  color: #fff;
  text-align: center;
  margin: 20px 0px;
  line-height: 58px;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
}

.midTxt {
  font-size: 24px;
  font-weight: bold;
  padding: 0px;
  text-transform: uppercase;
  display: inline;
  font-family: Roboto;

  span {
    padding: 0px 12px;
  }
}

.midTxtPB {
  font-size: 16px;
  font-weight: bold;
  color: #1B1B1B;
  line-height: 24px;
}

.midTxtP {
  font-size: 14px;
  padding: 0px;
  display: inline;
  color: #1B1B1B;
}

.highlightBg {
  background: #f8ba00;
}

.welcomeTxt p {
  color: #1B1B1B;
  font-family: $font__heading;
  font-size: 1.25em;
  line-height: 1.7;
}

.botSmltxt p {
  color: #A3A3A3;
  font-family: $font__heading;
  font-size: 10px;
  line-height: 1.6;
  padding-top: 18px;
}

.caption {
  h5.subtitle {
    color: #FFFFFF;
    font-family: RobotoBold;
    font-size: 24px;
    line-height: 26px;
    font-weight: 500;
    // text-shadow:0 1px 1px #333;
    margin: 0px;

  }

  p {
    color: #FFFFFF;
    font-family: $font__heading;
    font-size: 16px;
    line-height: 24px;
  }
}

.site-footer {
  background-color: #262626;

  h2 {
    color: #ffffff;
  }

  .about-kartas p {
    font-size: 10px;
  }

  .container {
    justify-content: space-between;
    flex-wrap: wrap;
    flex-flow: row wrap;
  }

  .container h4 {
    margin-bottom: 20px;
    padding-bottom: 15px;
    font-size: 16px;
  }

  .container ul li {
    font-size: 12px;
    line-height: 30px;
    text-transform: uppercase;
    color: #fff;
  }

  .container .office p {
    font-size: 12px;
    color: #fff;
    padding-top: 0px;
  }
}

h4.footBor {
  display: inline-block;
  font-family: "RobotoBold";
  font-weight: 500;
  padding-bottom: 5px;
  position: relative;
  text-transform: uppercase;
}

h4.footBor::before {
  border-bottom: 5px solid #FFF200;
  bottom: 0;
  content: "";
  height: 5px;
  position: absolute;
  width: 50px;
}

.botSmltxt {
  div.column:first-child {
    width: 37%;
    padding-right: 15%;
  }

  div.column:nth-child(2) {
    width: 15%;
  }

  div.column:nth-child(3) {
    width: 15%;
  }

  div.column:nth-child(4) {
    width: 18%;
  }
}

.lastFoot {
  background-color: #484848;
  padding: 17px 10px;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
}

.SmlTxt16 {
  p {
    color: #1B1B1B;
    font-family: $font__heading;
    font-size: 1em;
    line-height: 1.5em;
  }

  img {
    margin-bottom: 2.0em;
  }

  ul {
    list-style-type: none;
  }

  ul li {
    float: left;
    width: 50%;
    background: url(../images/arrowList.png) no-repeat left 5px;
    padding: 1px 10px 1px 30px;
  }
}

.StrBtnWrap a:visited {
  color: #ffffff;
}

a:visited {
  color: inherit;
}

.ProdImg {
  padding: 44px;
  background: #fff;
  box-sizing: border-box;
  margin-bottom: 1.25em;

  img {
    max-width: 100%;
  }
}

.prodWrap p {
  font-size: 1.0em;
  margin-bottom: 2.5em;
}

.whiteBg {
  background: #fff;
}

.grayBg {
  background: #f8f8f8;
}

.BigButton {
  background: #231d1f url("../images/pcIcon.png") no-repeat scroll left 17px;
  display: inline-block;
  font-size: 16px !important;
  margin: 20px 0;
  padding: 15px 35px 15px 80px;
  text-align: center;
  color: #ffffff !important;
}

.text-center {
  width: 100%;
  text-align: center;
}

.button, input[type="submit"], button {
  background-color: #231d1f;
  color: #ffffff;
  border-radius: 0;
}

.button:hover {
  background-color: #231d1f;
}

.home .content {
  padding-bottom: 4em;
}

@media (max-width: 768px) {
  .botSmltxt {
    div.column:first-child {
      width: 37%;
      padding-right: 5%;
    }

    div.column:nth-child(2) {
      width: 20%;
    }

    div.column:nth-child(3) {
      width: 20%;
    }

    div.column:nth-child(4) {
      width: 18%;
    }
  }
  .site-footer {
    padding-left: 0em;
    padding-right: 0em;
  }
}

@media (max-width: 480px) {
  .stripLine {
    background: #221d20;
    background: -moz-linear-gradient(top, #221d20 0%, #221d20 35%, #fff200 35%, #fff200 100%);
    background: -webkit-linear-gradient(top, #221d20 0%, #221d20 35%, #fff200 35%, #fff200 100%);
    background: linear-gradient(to bottom, #221d20 0%, #221d20 35%, #fff200 35%, #fff200 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#221d20', endColorstr='#fff200', GradientType=0);
    margin-bottom: 30px;

    .container {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .StrLogo {
    float: none;
    width: 100%;
    text-align: center;

    img {
      padding: 15px 0px;
      width: 120px;
    }
  }
  .StrMid {
    float: none;
    width: 100%;
    text-align: center;
  }
  .StrBtnWrap {
    display: none;
  }
  .midTxt {
    font-size: 18px;
  }
  .midTxtPB {
    font-size: 13px;
  }
  .midTxtP {
    font-size: 12px;
  }
  .highlightBg {
    background: none;
  }
  .botSmltxt {
    div.column:first-child {
      width: 100%;
      padding-right: 0%;
    }

    div.column:nth-child(2) {
      width: 100%;
    }

    div.column:nth-child(3) {
      width: 100%;
    }

    div.column:nth-child(4) {
      width: 100%;
    }
  }
  .site-header .container {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .site-footer {
    padding-left: 0em;
    padding-right: 0em;
  }
  .menu-mobile ul li {
    line-height: 2em;
  }
  h2 {
    font-size: 30px;
    font-size: 1.8rem;
  }
  .home-product-types .image {
    margin-bottom: 10px;
    height: 225px;
  }
  .site-header {
    height: auto;
    z-index: inherit !important;
  }
  .menu-mobile {
    background-color: #231D1F;

    ul li {
      height: 36px;
      text-align: center;
    }

    ul li a {
      color: #FFFFFF;
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      line-height: 36px;
      text-align: center;
    }
  }
  .welcomeTxt p, p {
    color: #1B1B1B;
    font-family: Roboto;
    font-size: 16px;
    line-height: 27px;
  }
  .mobile-logo {
    margin-bottom: 30px;
  }
  .menu-mobile.active {
    z-index: 100;
  }
  .menu-toggle.active {
    z-index: 101 !important;
  }
  .hero {
    height: 300px;
  }
  .home-product-types div.imageOverlay {
    height: 225px;
  }
  .container, .site-footer .container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .BigButton {
    background-position-y: 30px;
  }
  .form {
    margin-bottom: 30px;
  }
}
