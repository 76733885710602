// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
	font-size: ($sizeValue * 16) * 1px;
	font-size: $sizeValue * 1rem;
}

// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Clearfix
@mixin clearfix() {
	content: "";
	display: table;
	table-layout: fixed;
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
	clear: both;
}


/* Styles for small text with uppercase font style. Used on dates and post meta */
@mixin sans-uppercase {
	color: $color-gray;
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 400;
}

/// Mixin to prefix several properties at once
// Vendor prefixing for shorthand properties
@mixin prefix($property, $value) {
        -webkit-#{$property}: $value;
        -moz-#{$property}: $value;
        -ms-#{$property}: $value;
        -o-#{$property}: $value;
}

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex:  $values;
  -webkit-flex:  $values;
  -ms-flex:  $values;
  flex:  $values;
}

@mixin flex-wrap($values) {
  -webkit-box-flex-wrap: $values;
  -moz-box-flex-wrap:  $values;
  -webkit-flex-wrap:  $values;
  -ms-flex-wrap:  $values;
  flex-wrap:  $values;
}



@mixin order($val) {
  -webkit-box-ordinal-group: $val;  
  -moz-box-ordinal-group: $val;     
  -ms-flex-order: $val;     
  -webkit-order: $val;  
  order: $val;
}

$base-font-size: 16px;

@function em($px, $base: $base-font-size) {
  @return ($px / $base) * 1em;
}


/* Responsive media query mixins */
@mixin viewport($point) {
	@if $point == desktop {
		@media only screen and (min-width: 1440px)  { @content; }
	}
	@else if $point == laptop {
		@media ( max-width: 1280px )  { @content; }
	}
	@else if $point == tablet {
		@media ( max-width: 1024px )  { @content; }
	}
	@else if $point == mobile {
		@media ( max-width: 768px )  { @content; }
	}
}

