body,
button,
input,
select,
textarea {
	color: $color__text-main;
	font-family: $font__main;
	@include font-size(1);
	//overflow-y: scroll;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}


/*--------------------------------------------------------------
# Headings
--------------------------------------------------------------*/

h1, h2, h3, h4, h5, h6 {
	clear: both;
	color: $color-black;
	line-height: 1.2;
	font-family:$font__heading;
	margin:0 0 10px 0;
}

h1{
	@include font-size(3.750);
}

h2{
	@include font-size(3.125);
}

h3{
	@include font-size(1.875);
}

h4{
	@include font-size(1.250);
}

h5{
	@include font-size(1);
}

h6{
	@include font-size(1);
}




/*--------------------------------------------------------------
# Copy
--------------------------------------------------------------*/

p {
	margin-bottom: 1.5em;
	page-break-inside: avoid;
	margin-top:0;
	line-height:1.5;
}


dfn, cite, em, i {
	font-style: italic;
}

.dropcap {
	float: left;
    padding: 0;
    position: relative;
    color: #343e47;
    text-align: center;
    height: 80px;
    font-family: $font__main;
    font-weight: 700;
    @include font-size(6.563);
    line-height: .9;
    margin: 0 12px 10px 0;

	@include viewport(mobile) {
		font-size: 95px;
	}

	@include viewport(mobile) {
		font-size: 84px;
	}

	&:first-letter {

	}
}

blockquote {
	p{
		margin-bottom: 1em;
		color: $color-black;
		font-family: $font__highlight;
		font-style:italic;
		@include font-size(2);
		line-height: 1.6;
		position: relative;
		cite{
			font-family: $font__main;
			display: block;
		    @include font-size(1);
		    line-height: 1.25em;
		    font-style: normal;
		    margin-bottom: 0;
		}

	}
	&:before {
		content: "\201C";
		position: absolute;
		left: 0;
		font-size: 60px;
		width: 30px;
		top: 25px;
		line-height: 1;
	}

	@include viewport(mobile) {
		font-size: 20px;
		line-height: 1.5;
		padding-left: 12%;
		&:before {
			font-style: 52px;
			top: 21px;
		}
	}

	span{
		display: block;
		text-align: right;
		@include font-size(0.75);
		line-height: 40px;
		margin-top: 10px;
		text-transform: uppercase;
	}
	
}


.pullquote {
	&.left{
		float: left;
		margin-right: 1.5rem;
		width: calc(30% + 100px);
	}
	&.right{
		float: right;
		margin-left: 1.5rem;
		width: calc(30% + 100px);
	}
	p{
	    border-top: 3px solid #222222;
	    padding: 5% 0;
	    margin:10px;
	    font-family:$font__highlight;
	    @include font-size(1.375)
	}
}


address {
	margin: 0 0 1.5em;
}

pre {
	background: $color__background-pre;
	font-family: $font__pre;
	@include font-size(0.9375);
	line-height: $font__line-height-pre;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}


.comments-area pre {
	background: #d7dee5;
}

code, kbd, tt, var {
	font-size: 15px;
	font-family:$font__code;
}

abbr, acronym {
	border-bottom: 1px dotted #666;
	cursor: help;
}

mark, ins {
	background: #fff9c0;
	text-decoration: none;
}

sup, sub {
	font-size: 75%;
	height: 0;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	bottom: 1ex;
}

sub {
	top: .5ex;
}

small {
	font-size: 75%;
}

big {
	font-size: 125%;
}

.example{
	border:1px dotted $color-lightgray;
	padding:20px;
	margin-bottom:50px;
}


.highlight {
	background:$color-red;
}

.highlight2 {
	color:$color-red;
	font-family:$font__highlight;
	font-style:italic;
	@include font-size(1.375);
}


#page .more-link {
	font-weight: 700;

	&:after {
		content: " \2192";
	}
}