html {
	box-sizing: border-box;
}

*,
*:before,
*:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
	box-sizing: inherit;
}

body {
	background: $color__background-body; /* Fallback for when there is no custom background color defined. */
}

blockquote, q {
	quotes: "" "";

	&:before,
	&:after {
		content: "";
	}
}

hr {
	background-color: $color__background-hr;
	border: 0;
	height: 1px;
	margin-bottom: 1.5em;
}

/* Images */

img {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */
}

/* Lists */

ul, ol {
	margin: 0 0 1.4em 25px;
	padding:0;
	line-height:1.6;
}

ul {
	list-style: disc;
}

ol {
	list-style: decimal;
}

li > ul,
li > ol {
	margin-bottom: 0;
	margin-left: 1.5em;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0 1.5em 1.5em;
}



/* Tables */

table {
	font-size: 18px;
	margin-bottom: 5%;
	padding: 0;
	width: 100%;
}

table thead {
	background: $color-lightgray;
	th {
		font-weight: bold;
		text-align: left;
	}
}

table td, #content table th {
	padding: 10px;
}

table td {
	border-bottom: solid 1px #eaf1f5;
}

table tr:last-child td {
	border-bottom: none;
}

table tr:nth-child(even) {
	background:$color-lightgray;
}


/*******************************
            Wow
*******************************/
.wow{
	visibility: hidden;
}

/*******************************
            Slider
*******************************/

.hero{
    width: 100%;
    height: 100vh;
    box-sizing: content-box;
		&.banner{
			height:600px;
			position:relative;
		}
}


.home-carousel {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-bottom: 0;
}

.slick-slider{
	&.homepage-slider{
		width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-bottom: 0;
	}
	.slick-track{
		height: 100%;
	}
	.slick-list{
		height:100%;
	}
	.item {
	    width: 100%;
	    height: 100%  !important;
	    display: table;
	    background: no-repeat center center transparent;
	    background-size: cover;
	    .image{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: transparent !important;
				box-sizing: content-box;
				overflow: hidden;
				background: no-repeat center center transparent;
				background-size: cover;
				line-height: 0;
				z-index: 1;
			}
			.slide-container {
	      height: 100%;
	      width: 100%;
	      display: block;
	      vertical-align: middle;
				position:relative;
				z-index:2;
				margin-top:150px;
				@include viewport(mobile){
					padding:0 em(20px);
				}
				.slide-content{
					// transform: translateY(120px);
	        // opacity: 1;
	        // transition: 0.7s;
	        width: 100%;
	        max-width: 780px;
	        margin: 0 auto !important;
	        padding-top: 140px;
	        text-align: center;
					@include viewport(mobile){
						padding-top:0;
					}
					.title{
						color:$color-white;
						@include viewport(mobile){
							@include font-size(1.750);
							padding-top:0;
						}
					}
					.subtitle{
						clear:both;
						color:$color-white;
					}
					.button{
						clear:both;
					}
				}
			}

	}
}


/*******************************
            Gallery
*******************************/

.feed-column{
	/* max-width:100%;
	margin:0 -15px; */
	display: flex;
    -webkit-flex-flow: row wrap;
    justify-content: space-around;
    width: 100%;
    height: auto;
	.item{
		width: 100%;
	    min-height: 250px;
	    padding:1rem;
		// float:left;
		// margin-right:20px;
		// margin-bottom:20px;
		-webkit-transform: translate3d(0,0,0);
		@include viewport(desktop){
	        width: 20%;
    		min-height: 220px;
    		&:nth-child(5n+5){
    			margin-right:0;
    		}
	    }
	    @include viewport(laptop){
	        width: calc(22% - 8.33px);
			min-height: 300px;
	    }
	    @include viewport(mobile){
	        width:100% !important;
	        min-height:300px;
	    }
	}

	.item{

	    .entry-image{
			width: 100%;
			height: 100%;
			min-height:inherit;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center top;
			-moz-transition: all .2s ease-out;
			-ms-transition: all .2s ease-out;
			-o-transition: all .2s ease-out;
			-webkit-transition: all .2s ease-out;
			transition: all .2s ease-out;
	    }
		img{
			max-width:100%;
		}
	}
}
