$color-red:#C02942;
$color-black:#131313;
$color-white:#fbfbfb;
$color-teal:#2FBBAF;
$color-orange:#ff5851;
$color-yellow:#FFF200;
$color-gray:#a2a1a1;
$color-lightgray:#E0E1E2;
$color-darkgray:#484848;
$color-blue:#E0E1E2;
$color-darkblue:#07303A;

$color__primary:$color-yellow;
$color__secondary:$color-darkgray;
$color__active:$color-darkblue;
$color__accent1:$color-yellow; //use for Success Messages
$color__accent2:$color-orange; //use for Success Messages
$color__accent3:$color-orange; //use for Error Messages

$color__background-body: $color-white;
$color__background-screen: $color-white;
$color__background-hr: $color-lightgray;
$color__background-button: $color-black;
$color__background-pre: $color-lightgray;
$color__background-ins: $color-gray;

$color__text-screen:$color-darkgray;
$color__text-input: #666;
$color__text-input-focus: #111;
$color__link: $color__secondary;
$color__link-visited: $color__secondary;
$color__link-hover: $color-gray;
$color__text-main:$color-darkgray;

$color__border-button: #ccc #ccc #bbb;
$color__border-button-hover: #ccc #bbb #aaa;
$color__border-button-focus: #aaa #bbb #bbb;
$color__border-input: #ccc;
$color__border-abbr: #666;

$size__site-main: 100%;
$size__site-sidebar: 25%;

//'Roboto', sans-serif;
// 'Playfair Display', serif;
$font__highlight: 'Roboto', sans-serif;
$font__heading: 'Roboto', sans-serif;
$font__main: 'Roboto', sans-serif;
$font__code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", Courier, monospace;
$font__line-height-body: 1.5;
$font__line-height-pre: 1.6;
