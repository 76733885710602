/*!
Theme Name: Starter HTML
Theme URI: http://www.digitalmohawk.com
Author: Digitalmohawk
Author URI: http://www.digitalmohawk.com
Description: Starter HTML
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: starter

Basic boilerplate for any kind of website project. Distributed under the terms of the GNU GPL v2 or later.

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal http://necolas.github.com/normalize.css/
*/




@import "variables";
@import "mixins-master";

/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
@import "normalize";

/*--------------------------------------------------------------
# Site Layout
--------------------------------------------------------------*/
@import "site";

/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/
@import "fonts";

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
@import "typography";

/*--------------------------------------------------------------
# Icons
--------------------------------------------------------------*/
@import "icons";


/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
@import "elements";

/*--------------------------------------------------------------
# Menu
--------------------------------------------------------------*/
@import "menu";


/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
@import "forms";

/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
@import "navigation";

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
@import "accessibility";

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
@import "alignments";

/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/
@import "clearings";


/*--------------------------------------------------------------
# Plugins
--------------------------------------------------------------*/
@import "plugins";


/*--------------------------------------------------------------
# Custom
--------------------------------------------------------------*/
@import "custom";
